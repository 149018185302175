import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './components/NavBar';
import GuardianOnsiteRosterWidget from 'GuardianOnsiteRosterWidget/lib/';

function Index() {
    return <h2 style={{ textAlign: 'center' }}>Guardian Onsite Roster</h2>;
}

const App: React.FunctionComponent = () => {
    return (
        <Fragment>
            <Router>
                <div className='App'>
                    <NavBar />
                    <Route path='/' exact component={Index} />
                    <Route path='/osrw' exact component={GuardianOnsiteRosterWidget} />
                </div>
            </Router>
        </Fragment>
    );
};

export default App;
