// @ts-nocheck
import ReactDOM from 'react-dom';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import createGuardianAuthStore from 'GuardianAuth/lib/store/guardianAuthStore';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './css/index.css';
import {
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO
} from './config/globals';
import { AnyAction, Store } from 'redux';
import App from './App';
import React from 'react';

GuardianAuth.init(
    createGuardianAuthStore(),
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO
);

GuardianAuth.setTitle('Tester');

export default function showPage(guardianAuthStore: Store<never, AnyAction>): void {
    ReactDOM.render(
        <Provider store={guardianAuthStore} context={AUTH_CONTEXT}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}

if (GuardianAuth.guardianAuthStore) {
    GuardianAuth.authAndShowPage(() => {
        showPage(GuardianAuth.guardianAuthStore);
    });
}
